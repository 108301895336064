
interface ICardWrapper {
  widthPercentage?: number;
  children: JSX.Element;
}

interface Map {
  [key: number]: string | undefined;
}

const CSS_WIDTH_PERCENTAGE: Map = {
  20: "w-[calc(100vw_*_20_/_100)]",
  30: "w-[calc(100vw_*_30_/_100)]",
  75: "w-[calc(100vw_*_75_/_100)]",
  80: "w-[calc(100vw_*_80_/_100)]",
  100: "w-[calc(100vw_-_20px)] px-[0.6rem]",
};

const CardWrapper = ({
  widthPercentage,
  children,
}: ICardWrapper): JSX.Element => {
  return (
    <div
      className={`${
        CSS_WIDTH_PERCENTAGE[widthPercentage ?? 100]
      } mb-3 tablet:max-w-full tablet:px-[0.625rem]`}
    >
      {children}
    </div>
  );
};

export default CardWrapper;
