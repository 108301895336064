import { IReviewCardProps } from '@libs/cards/ReviewCard'
import ReviewsQuotes from '@components/icons/ReviewsQuotes'
import ReviewerContent from '@libs/cards/components/ReviewCard/ReviewerContent'
import DangerouslySetHtml from '@libs/parser/components/DangerouslySetHtml'
import React, { useRef } from 'react'

const DesktopReviewCardWithImages = ({
  review,
  headshot,
  reviewer,
  country,
  date,
}: IReviewCardProps): JSX.Element => {
  const parentRef = useRef<HTMLDivElement>(null)
  return (
    <div className='relative h-[30.75rem] pt-[2.25rem]'>
      <div className='absolute top-0 ml-8 flex'>
        <ReviewsQuotes />
      </div>
      <div className='flex h-full flex-col justify-between rounded-3xl border border-blue-10 px-8 py-6 pt-[3.375rem]'>
        <div className='flex-grow text-left text-darkgray' ref={parentRef}>
          <DangerouslySetHtml html={review} parentRef={parentRef} />
        </div>
        <ReviewerContent
          headshot={headshot}
          reviewer={reviewer}
          country={country}
          date={date}
        />
      </div>
    </div>
  )
}

export default DesktopReviewCardWithImages
