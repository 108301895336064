
const ReviewsQuotes = (): JSX.Element => {
  return (
    <div className="rounded-3xl border-[0.25rem] border-white bg-blue-10 py-[1.586rem] px-[1.149rem]">
      <svg
        width="39"
        height="25"
        viewBox="0 0 39 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.67993 7.81161C8.58492 6.09687 9.81853 4.02642 12.1292 2.03198C11.9756 2.06771 11.8859 2.07987 11.8023 2.10951C7.5839 3.5985 4.3779 6.22533 2.69889 10.4514C1.60057 13.215 1.51241 16.0387 2.78249 18.7955C4.38018 22.2622 8.0179 23.9701 11.6693 23.0192C15.266 22.0828 17.7005 18.6883 17.4444 14.9662C17.1464 10.6285 13.2852 7.36164 8.96952 7.79565C8.88211 7.80477 8.7947 7.80553 8.67993 7.81161Z"
          stroke="#07116E"
          strokeWidth="2.5"
          strokeLinejoin="round"
        />
        <path
          d="M28.2903 7.81161C28.1953 6.09687 29.4289 4.02642 31.7395 2.03198C31.586 2.06771 31.4963 2.07987 31.4127 2.10951C27.1943 3.5985 23.9882 6.22533 22.3092 10.4514C21.2109 13.215 21.1228 16.0387 22.3928 18.7955C23.9905 22.2622 27.6283 23.9701 31.2797 23.0192C34.8763 22.0828 37.3109 18.6883 37.0547 14.9662C36.7568 10.6285 32.8956 7.36164 28.5799 7.79565C28.4925 7.80477 28.4051 7.80553 28.2903 7.81161Z"
          stroke="#07116E"
          strokeWidth="2.5"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default ReviewsQuotes;
