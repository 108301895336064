import { IReview } from "@components/pages/travel-consultant/components/TCDetailsLayout";
import { IImageComponentResponse, IMediaComponentResponse, } from "@customTypes/componentResponses";
import { ISearchQuery } from "@customTypes/filtersProvider";
import {
  IAccommodationResponse,
  IActivityResponse,
  ILocationResponse,
  IPackageResponse,
  ITagResponse,
} from "@customTypes/modelResponses";

export interface processedPacLocType
  extends IPackageResponse,
    ILocationResponse {
  isMultiDestinationsPackage?: boolean;
}

export interface IProcessedTagActivityType<T>
  extends Omit<ITagResponse, "Media">,
    Omit<IActivityResponse, "Media"> {
  Media: T;
}

const extractTags = (packageData: processedPacLocType): string[] => {
  const result: Array<string | undefined> = [];

  const activityTypes = packageData?.activities?.map((activity) =>
    activity?.activity_types?.map((activityType) => activityType.Title)
  );
  const activityTypesList = [
    ...new Set(
      activityTypes
        ?.map((activity) => {
          return activity?.[0];
        })
        .filter((activity) => activity !== undefined)
    ),
  ];
  if (packageData.tags) {
    result.push(...packageData.tags.map((tag) => tag.name));
  }
  if (packageData.activities) {
    result.push(...packageData.activities.map((activity) => activity.Title));
  }
  result.push(...activityTypesList);

  // PACKAGE OR LOCATION CASES
  if (packageData.locations) {
    // if it is package
    const locationTypes = packageData.locations.map((location) =>
      location?.location_types?.map((locationType) => locationType.Title)
    );

    const locationTypesList = [
      ...new Set(
        locationTypes
          ?.map((location) => {
            return location?.[0];
          })
          .filter((location) => location !== undefined)
      ),
    ];

    result.push(...packageData.locations.map((location) => location.Title));
    result.push(...locationTypesList);
  } else {
    // if it is location
    if (packageData.location_types) {
      result.push(
        ...packageData?.location_types?.map(
          (locationType) => locationType.Title
        )
      );
    }
  }
  const filteredResult = result.filter(
    (result) => result !== undefined
  ) as string[];

  return [...new Set(filteredResult)];
};

interface SortByTitleItem {
  title?: string;
  Title?: string;
}

const sortByTitle = (a: SortByTitleItem, b: SortByTitleItem): -1 | 0 | 1 => {
  // Checking for both title and Title as they are still not cleaned through the project
  return (a.title ?? a.Title) !== (b.title ?? b.Title)
    ? // @ts-expect-error
    (a.title ?? a.Title) < (b.title ?? b.Title)
      ? -1
      : 1
    : 0;
};

const getSearchTags = (): ISearchQuery => {
  return (
    JSON.parse(
      // @ts-expect-error
      typeof window !== "undefined"
        ? localStorage.getItem("USER_SEARCH_FILTERS")
        : null
    ) || []
  );
};

export const sortPackagesAndLocations = (
  packages?: IPackageResponse[] & ILocationResponse[],
  sortingType?: "package-search" | "locationWithoutLikes",
  priority?: "multiDestinationsPackage"
): processedPacLocType[] => {
  const searchTags = getSearchTags();
  let processedPackages: processedPacLocType[] | undefined = [];

  if (typeof window !== "undefined") {

    processedPackages = packages?.map((pac: Record<string, any>) => {
      return pac as processedPacLocType;
    });
  }

  if (sortingType !== "package-search") {
    processedPackages = processedPackages?.sort((a, b) => sortByTitle(a, b));
  }
  if (priority === "multiDestinationsPackage") {
    packages?.sort(
      (a, b) =>
        // @ts-expect-error
        b?.isMultiDestinationsPackage - a?.isMultiDestinationsPackage
    );
  }
  if (processedPackages) {
    processedPackages
      .sort((a, b) => {
        if (sortingType === "locationWithoutLikes") {
          // return because locations don't have tags on homePage and landingPages
          return 0;
        }
        const aPackageTags = extractTags(a);
        const bPackageTags = extractTags(b);
        const searchTagsList = [
          ...(searchTags.experiences ?? []),
          ...(searchTags.locationTypes ?? []),
          ...(searchTags.activityTypes ?? []),
        ].map((item) => item.title);

        const resultB = bPackageTags.some((tag) =>
          searchTagsList.includes(tag)
        );
        const resultA = aPackageTags.some((tag) =>
          searchTagsList.includes(tag)
        );
        // @ts-expect-error
        return resultB - resultA;
      })

      .sort(
        (a, b) =>
          // @ts-expect-error
          b?.recommended - a?.recommended
      );

  }
  if (processedPackages) {
    return processedPackages;
  } else {
    return [];
  }
};

export const sortAccommodations = (
  accommodations?: IAccommodationResponse[],
  isSanitized?: boolean
): IAccommodationResponse[] => {
  if (accommodations) {
    isSanitized
      ? accommodations
        .sort((a, b) => sortByTitle(a, b))
        // @ts-expect-error
        .sort((a, b) => a.Rate - b.Rate)
        // @ts-expect-error
        .sort((a, b) => b.Recommended - a.Recommended)
      : accommodations
        // @ts-expect-error
        .sort((a, b) => sortByTitle(a.accommodation, b.accommodation))
        // @ts-expect-error
        .sort((a, b) => a.accommodation.Rate - b.accommodation.Rate)
        .sort(
          // @ts-expect-error
          (a, b) => b.accommodation.Recommended - a.accommodation.Recommended
        )
  }
  return accommodations || [];
};

export const sortActivities = (
  activities?: ITagResponse[] | IActivityResponse[]
): Array<
  IProcessedTagActivityType<IMediaComponentResponse | IImageComponentResponse>
> => {
  const searchTags = getSearchTags();
  const searchExperiences: Array<string | undefined> =
    searchTags.experiences?.map((item) => item.title);
  const searchActivityTypes = searchTags.activityTypes?.map(
    (item) => item.title
  );
  let processedActivities:
    | Array<
    IProcessedTagActivityType<
      IMediaComponentResponse | IImageComponentResponse
    >
  >
    | undefined = [];
  if (typeof window !== "undefined") {
    processedActivities = activities?.map((activity: Record<string, any>) => {
      return activity as IProcessedTagActivityType<
        IMediaComponentResponse | IImageComponentResponse
      >;
    });
  }
  if (processedActivities) {
    processedActivities
      .sort((a, b) => sortByTitle(a, b))
      .sort((a, b) => {
        // sort by activity type
        const aTypes = a?.activity_types?.map((type) => type.Title);
        const bTypes = b?.activity_types?.map((type) => type.Title);
        const resultB = bTypes?.some((tag) =>
          searchActivityTypes?.includes(tag)
        );
        const resultA = aTypes?.some((tag) =>
          searchActivityTypes?.includes(tag)
        );
        // @ts-expect-error
        return resultB - resultA;
      })
      .sort((a, b) => {
        // sort by experience
        const aTags = a?.tags?.map((tag) => tag.name);
        const bTags = b?.tags?.map((tag) => tag.name);
        const resultB = bTags?.some((tag) => searchExperiences?.includes(tag));
        const resultA = aTags?.some((tag) => searchExperiences?.includes(tag));
        // @ts-expect-error
        return resultB - resultA;
      })
      // @ts-expect-error
      .sort((a, b) => b.recommended - a.recommended)
  }
  return processedActivities || [];
};

export const sortSanitizedReviews = (reviews: IReview[]): IReview[] => {
  reviews.sort((a, b) => {
    return a.id - b.id;
  });
  return reviews;
};
