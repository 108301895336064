import useAppContext from '@hooks/useAppContext'
import { ResponsiveGridConfig } from '@customTypes/index'
import { useMemo } from 'react'

interface UseResponsiveGridConfigParams {
  config: ResponsiveGridConfig
  itemCount: number
  visibleRows?: number
}

export default function useResponsiveGridConfig({
  config,
  itemCount,
  visibleRows,
}: UseResponsiveGridConfigParams): {
  numItemsShown: number
  itemsPerRow: number
  numRowsShown: number
  itemHeight: number
  totalHeight: number
} {
  const { mobile, tablet, desktopSmall, desktop, desktopLarge } = config
  const { isMobile, isTablet, isDesktop, screenTypeVariant } = useAppContext()
  const responsiveConfig = useMemo(() => {
    if (isMobile) return mobile
    if (isTablet) return tablet || mobile
    if (isDesktop) {
      if (screenTypeVariant === 'small')
        return desktopSmall || desktop || tablet || mobile
      if (screenTypeVariant === 'large')
        return desktopLarge || desktop || desktopSmall || tablet || mobile
      return desktop || desktopSmall || tablet || mobile
    }
    return mobile
  }, [
    isMobile,
    isTablet,
    isDesktop,
    screenTypeVariant,
    mobile,
    tablet,
    desktop,
    desktopSmall,
    desktopLarge,
  ])

  if (String(process.env.NODE_ENV) === 'development')
    console.log(
      isMobile
        ? 'mobile'
        : isTablet
          ? 'tablet'
          : screenTypeVariant === 'large'
            ? 'desktopLarge'
            : screenTypeVariant === 'small'
              ? 'desktopSmall'
              : 'desktop',
    )

  const { itemsPerRow, itemHeight } = responsiveConfig

  const numRowsShown = visibleRows ?? Math.ceil(itemCount / itemsPerRow)

  const numItemsShown = Math.min(itemsPerRow * numRowsShown, itemCount)

  const totalHeight = numRowsShown * itemHeight

  return { numItemsShown, numRowsShown, itemsPerRow, itemHeight, totalHeight }
}
