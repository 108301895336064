import { IReviewCardProps } from '@libs/cards/ReviewCard'
import ReviewsQuotes from '@components/icons/ReviewsQuotes'
import ReviewerContent from '@libs/cards/components/ReviewCard/ReviewerContent'
import MobileReviewInnerCarousel from '@libs/cards/components/MobileReviewInnerCarousel'
import React, { useRef } from 'react'
import DangerouslySetHtml from '@libs/parser/components/DangerouslySetHtml'

const MobileReviewCard = ({
  review,
  headshot,
  reviewer,
  country,
  date,
  images,
}: IReviewCardProps): JSX.Element => {
  const parentRef = useRef<HTMLDivElement>(null)
  return (
    <div className='h-[30.25rem] flex w-full flex-col'>
      <div className='z-[1] mx-auto mb-9'>
        <ReviewsQuotes />
      </div>
      <div className='relative'>
        <div ref={parentRef}>
          <DangerouslySetHtml html={review} parentRef={parentRef} />
        </div>
        <ReviewerContent
          headshot={headshot}
          reviewer={reviewer}
          country={country}
          date={date}
        />
        {images?.length && (
          <div className='mt-6'>
            <MobileReviewInnerCarousel images={images} reviewer={reviewer} />
          </div>
        )}
      </div>
    </div>
  )
}

export default MobileReviewCard
