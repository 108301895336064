import React, { useMemo, useState } from 'react'
import { ResponsiveGridConfig } from '@customTypes/index'
import useResponsiveGridConfig from '@hooks/useResponsiveGridConfig'

// Assuming 'useAppContext' and other imports are correctly set up.

export interface GridGalleryProps {
  responsiveGridConfig: ResponsiveGridConfig
  components?: JSX.Element[]
  moreWhat?: string // e.g.: set to 'Images' to show 'More Images' link instead of just 'More'
  noCardsLimit?: boolean
}

const getWidthClass = (itemsPerRow: number) => {
  // Map the number of items per row to Tailwind's fractional width classes
  const widthMap = ['na', 'w-full', 'w-1/2', 'w-1/3', 'w-1/4', 'w-1/6']
  return widthMap[itemsPerRow] || 'w-full' // Default to full width if no mapping exists
}

const GridGallery = ({
  components = [],
  responsiveGridConfig,
  moreWhat,
  noCardsLimit = false, // if true, make sure parent's SectionWrapper has a heightClass set
}: GridGalleryProps): JSX.Element => {
  const [showAll, setShowAll] = useState(false)
  const allAreVisible = showAll || noCardsLimit
  const { itemsPerRow, itemHeight, totalHeight, numItemsShown } =
    useResponsiveGridConfig({
      config: responsiveGridConfig,
      itemCount: components.length,
      visibleRows: allAreVisible ? undefined : 1,
    })

  const visibleComponents = useMemo(
    () => (components ? components.slice(0, numItemsShown) : []),
    [components, numItemsShown],
  )

  // Calculate width class name based on itemsPerRow
  const itemWidthClass = getWidthClass(itemsPerRow)

  return (
    <div>
      <div
        className='flex flex-wrap flex-row justify-center'
        style={{ height: totalHeight }}
      >
        {visibleComponents.map((itemComponent, index) => (
          <div
            key={index}
            className={`flex justify-center ${itemWidthClass} p-1`}
            style={{ height: itemHeight }}
          >
            {' '}
            {/* Adjust padding as needed */}
            {itemComponent}
          </div>
        ))}
      </div>
      {!allAreVisible && components.length > itemsPerRow && (
        <div className='text-center mt-4 mb-2'>
          <button
            onClick={() => setShowAll(true)}
            className='text-blue-500 hover:underline'
          >
            {`More${moreWhat ? ` ${moreWhat}` : ''}`}
          </button>
        </div>
      )}
    </div>
  )
}

export default GridGallery
