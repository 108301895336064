import ImageCDN from '@components/skeleton/ImageCDN'
import { ImageDefault } from '@components/utils/Utils'

const DesktopReviewGallery = ({ images, reviewer }: any): JSX.Element => {
  return (
    <div className='flex w-full flex-row gap-4 '>
      <div className='relative w-[26.25rem] basis-3/5'>
        <ImageCDN
          className='rounded-3xl'
          layout='fill'
          objectFit='cover'
          width='420'
          height='564'
          src={ImageDefault(images.length > 0 ? images[0] : null)}
          alt={`${reviewer} photo 1`}
          priority={false}
        />
      </div>
      <div className='flex basis-2/5 flex-col gap-4'>
        <div className='relative basis-1/2'>
          <ImageCDN
            className='rounded-3xl'
            layout='fill'
            objectFit='cover'
            width='312'
            height='274'
            src={ImageDefault(images.length > 1 ? images[1] : null)}
            alt={`${reviewer} photo 2`}
            priority={false}
          />
        </div>
        <div className='relative basis-1/2'>
          <ImageCDN
            className='rounded-3xl'
            layout='fill'
            objectFit='cover'
            width='312'
            height='274'
            src={ImageDefault(images.length > 2 ? images[2] : null)}
            alt={`${reviewer} photo 3`}
            priority={false}
          />
        </div>
      </div>
    </div>
  )
}

export default DesktopReviewGallery
