
function LocationIcon(): JSX.Element {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_14246_28715)">
        <path
          d="M10.7205 7.63015C10.7205 8.83524 9.74357 9.81217 8.53847 9.81217C7.33337 9.81217 6.35645 8.83524 6.35645 7.63015C6.35645 6.42505 7.33337 5.44812 8.53847 5.44812C9.74357 5.44812 10.7205 6.42505 10.7205 7.63015Z"
          strokeWidth="1.5"
        />
        <path
          d="M13.9009 7.63002C13.9009 9.09226 13.2421 10.5748 12.1539 12.0006C11.1731 13.2856 9.87931 14.4768 8.52836 15.5003C7.21127 14.5401 5.92535 13.3546 4.943 12.0585C3.84727 10.6128 3.17578 9.09256 3.17578 7.63002C3.17578 4.66836 5.57668 2.26746 8.53835 2.26746C11.5 2.26746 13.9009 4.66836 13.9009 7.63002Z"
          strokeWidth="1.5"
        />
      </g>
      <defs>
        <clipPath id="clip0_14246_28715">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0.425781 0.85083)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default LocationIcon;
