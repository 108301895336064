
interface Map {
  [key: string]: JSX.Element;
}

interface IMobileReviewsBackground {
  variant: string;
}

const BACKGROUND: Map = {
  top: (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 390 107"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 676.085V57.4502C73.3957 15.9553 163.306 -4.64146 227.142 0.882389C288.537 6.25307 354.114 43.978 390 98.5118V721.558C384.881 728.875 379.323 735.76 373.449 742.269C343.13 756.587 307.657 764.236 274.546 764.022C224.276 763.43 179.283 744.773 127.686 723.378C109.052 715.651 89.5579 707.568 68.6427 699.849C55.009 694.87 40.7754 690.03 26.4176 685.148C17.6219 682.156 8.77955 679.15 0 676.085Z"
        fill="#F3F4F8"
      />
    </svg>
  ),
  bottom: (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 390 110"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_16705_142983)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 22.0855V-596.55C73.3957 -638.045 163.306 -658.641 227.142 -653.118C288.537 -647.747 354.114 -610.022 390 -555.488V67.5577C384.881 74.8755 379.323 81.7605 373.449 88.2691C343.13 102.587 307.657 110.236 274.546 110.022C224.276 109.43 179.283 90.7735 127.686 69.3779C109.052 61.6514 89.5579 53.5678 68.6427 45.8489C55.009 40.8702 40.7754 36.03 26.4176 31.1476C17.6219 28.1566 8.77955 25.1497 0 22.0855Z"
          fill="#F3F4F8"
        />
      </g>
      <defs>
        <clipPath id="clip0_16705_142983">
          <rect width="390" height="110" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
};

const MobileBottomBG = ({ variant }: IMobileReviewsBackground): JSX.Element => {
  return (
    <div className="-z-10 w-screen">
      {BACKGROUND[variant] ?? BACKGROUND.top}
    </div>
  );
};

export default MobileBottomBG;
