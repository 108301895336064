import ImageCDN from '@components/skeleton/ImageCDN'
import { ImageDefault } from '@components/utils/Utils'
import GridGallery from '@libs/slider/GridGallery'
import { useMemo } from 'react'
import { IImageComponentResponse } from '@customTypes/componentResponses'

const MobileReviewInnerCarousel = ({ images, reviewer }: any): JSX.Element => {
  const imageComponents = useMemo(() => {
    return images.map((img: IImageComponentResponse, urlIndex: any) => (
      <div
        className='h-[160px] min-w-[calc(100vw_*_40_/_100)] pr-2'
        key={urlIndex}
      >
        <div className='h-[148px] overflow-hidden rounded-2xl'>
          <ImageCDN
            className='rounded-2xl'
            src={ImageDefault(img)}
            width={148}
            height={148}
            layout='responsive'
            objectFit='cover'
            alt={`${reviewer}photo${urlIndex}`}
          />
        </div>
      </div>
    ))
  }, [images, reviewer])

  return (
    <div style={{ height: 320, width: '100%' }}>
      <GridGallery
        components={imageComponents}
        responsiveGridConfig={{
          mobile: {
            itemsPerRow: 2,
            itemHeight: 160,
          },
        }}
        moreWhat='Images'
      />
    </div>
  )
}

export default MobileReviewInnerCarousel
