import { IReviewCardProps } from '@libs/cards/ReviewCard'
import ReviewerHeadShotImage from './ReviewerHeadShotImage'

const ReviewerContent = ({
  headshot,
  reviewer,
  country,
  date,
}: Pick<
  IReviewCardProps,
  'headshot' | 'reviewer' | 'country' | 'date'
>): JSX.Element => {
  return (
    <div className='flex items-center self-start'>
      <ReviewerHeadShotImage headshot={headshot} />
      <div className='flex flex-col pl-6'>
        <p className='text-left text-p font-medium text-black'>
          {`${reviewer}`}
        </p>
        <p className='inline text-p font-medium text-darkgray'>{country}</p>
        <p className='inline text-p font-medium text-darkgray'>{date}</p>
      </div>
    </div>
  )
}

export default ReviewerContent
