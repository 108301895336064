import { IReviewCardProps } from '@libs/cards/ReviewCard'
import ReviewsQuotes from '@components/icons/ReviewsQuotes'
import ReviewerContent from '@libs/cards/components/ReviewCard/ReviewerContent'
import DesktopReviewGallery from '@libs/cards/components/ReviewCard/DesktopReviewGallery'
import DangerouslySetHtml from '@libs/parser/components/DangerouslySetHtml'
import React, { useRef } from 'react'

const DesktopReviewCardWithImages = ({
  review,
  headshot,
  reviewer,
  country,
  date,
  images,
}: IReviewCardProps): JSX.Element => {
  const parentRef = useRef<HTMLDivElement>(null)
  return (
    <div className='flex w-full flex-row gap-4'>
      <div
        className={`h-[40.25rem] w-full border border-blue-10 ${
          images?.length ? 'max-w-[33.125rem]' : 'w-full'
        } flex flex-col justify-between rounded-3xl py-8 px-[3.375rem]`}
      >
        <div className='flex flex-col gap-6'>
          <div className='flex'>
            <ReviewsQuotes />
          </div>
          <div className='flex-grow text-left text-darkgray' ref={parentRef}>
            <DangerouslySetHtml html={review} parentRef={parentRef} />
          </div>
        </div>
        <ReviewerContent
          headshot={headshot}
          reviewer={reviewer}
          country={country}
          date={date}
        />
      </div>
      {images?.length && (
        <DesktopReviewGallery images={images} reviewer={reviewer} />
      )}
    </div>
  )
}

export default DesktopReviewCardWithImages
