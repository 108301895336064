import ImageCDN from "@components/skeleton/ImageCDN";
import { ImageDefault } from "@components/utils/Utils";
import { IReviewCardProps } from "@libs/cards/ReviewCard";

const ReviewerHeadShotImage = ({
  headshot,
}: Pick<IReviewCardProps, "headshot">): JSX.Element => {
  return (
    <div className="ml-[0.25rem] h-[72px] w-[72px] rounded-2xl ring-[0.25rem] ring-white desktop:ml-0 desktop:ring-0">
      <ImageCDN
        className="rounded-2xl"
        // Logic of src to be cleaned when all data is sanitized;
        src={ImageDefault(
          typeof headshot === "string"
            ? headshot
            : headshot?.length
            ? headshot[0]
            : null
        )}
        width="72"
        height="72"
        layout="fixed"
        objectFit="cover"
        alt="Reviewer headshot"
      />
    </div>
  );
};

export default ReviewerHeadShotImage;
