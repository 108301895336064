import { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
// @ts-ignore
import Youtube from 'react-lazyload-youtube';
import 'react-lazyload-youtube/dist/index.css';

const postProcessHtml = (html: string): string => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');

  addClassesToAnchors(doc);
  removeFindTripLinks(doc);

  return doc.body.innerHTML;
}

const addClassesToAnchors = (doc: Document): void => {
  const anchors = doc.querySelectorAll('a');
  anchors.forEach(anchor => {
    const children = Array.from(anchor.childNodes);
    const hasValidChildren = children.every(child =>
      child.nodeType === Node.TEXT_NODE ||
      (child.nodeType === Node.ELEMENT_NODE && (child as HTMLElement).tagName === 'STRONG')
    );

    if (hasValidChildren) {
      anchor.classList.add('text-lightblue', 'hover:text-darkblue', 'underline', 'font-sans');
    }
  });
}

const removeFindTripLinks = (doc: Document): void => {
  const findTripLinks = doc.querySelectorAll('a[href="/find-trip"]');
  findTripLinks.forEach(link => {
    link.remove();
  });
}

const lazyLoadYouTube = (parentElement: HTMLElement): void => {
  const mediaFigures = parentElement.querySelectorAll('figure.media');

  mediaFigures.forEach(figure => {
    const div = figure.querySelector('div[data-oembed-url*="youtu.be"]');
    if (div) {
      const url = div.getAttribute('data-oembed-url');
      const videoId = url?.split('v=')[1] || url?.split('/').pop();
      if (videoId) {
        const youtubeContainer = document.createElement('div');
        div.innerHTML = ''; // Clear existing content
        div.appendChild(youtubeContainer);

        // Create a wrapper to maintain the aspect ratio
        const aspectRatioBox = document.createElement('div');
        aspectRatioBox.style.position = 'relative';
        aspectRatioBox.style.width = '100%';
        aspectRatioBox.style.paddingBottom = '56.25%'; // 9/16 * 100%
        aspectRatioBox.style.height = '0';
        aspectRatioBox.appendChild(youtubeContainer);

        div.appendChild(aspectRatioBox);

        // Set styles for the YouTube container
        youtubeContainer.style.position = 'absolute';
        youtubeContainer.style.top = '0';
        youtubeContainer.style.left = '0';
        youtubeContainer.style.width = '100%';
        youtubeContainer.style.height = '100%';

        // Cast figure to HTMLElement to access the style property
        const figureElement = figure as HTMLElement;
        figureElement.style.marginTop = '1rem'; // Add top margin
        figureElement.style.marginBottom = '1rem'; // Add bottom margin
        figureElement.style.height = 'auto'; // Ensure figure takes height of its content

        const root = createRoot(youtubeContainer);
        root.render(<Youtube width="100%" height="100%" videoId={videoId} />);
      }
    }
  });
};

export default function DangerouslySetHtml({ html, parentRef }: { html?: string, parentRef: any }): null {
  useEffect(() => {
    if (html && parentRef.current) {
      parentRef.current.innerHTML = postProcessHtml(html);
      lazyLoadYouTube(parentRef.current); // Apply lazy loading after HTML is set
    }
  }, [html, parentRef]);

  return null;
}
