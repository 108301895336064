import { IImageComponentResponse } from '@customTypes/componentResponses'
import useAppContext from '@hooks/useAppContext'
import ReviewBackgroundElements from '@libs/cards/assets/ReviewBackgroundElements'
import DesktopReviewCardNoImages from './components/ReviewCard/DesktopReviewCardNoImages'
import DesktopReviewCardWithImages from './components/ReviewCard/DesktopReviewCardWithImages'
import MobileReviewCard from './components/ReviewCard/MobileReviewCard'

type LeftOffsetType = '10px' | '20px'

interface IReviewCardSliderWrapper {
  leftOffset?: LeftOffsetType
  children: JSX.Element
}

export interface IReviewCardProps {
  review: string
  headshot?: string | IImageComponentResponse[] | null
  country: string
  date?: string | null
  reviewer: string
  images?: IImageComponentResponse[]
  hasGallery?: boolean
}

const ReviewCard = ({
  review,
  headshot,
  reviewer,
  country,
  date,
  images,
  hasGallery,
}: IReviewCardProps): JSX.Element => {
  const { isDesktop } = useAppContext()
  return !isDesktop ? (
    <MobileReviewCard
      review={review}
      headshot={headshot}
      reviewer={reviewer}
      country={country}
      date={date}
      images={images}
    />
  ) : hasGallery ? (
    <DesktopReviewCardWithImages
      review={review}
      headshot={headshot}
      reviewer={reviewer}
      country={country}
      date={date}
      images={images}
    />
  ) : (
    <DesktopReviewCardNoImages
      review={review}
      headshot={headshot}
      reviewer={reviewer}
      country={country}
      date={date}
    />
  )
}

const LEFT_OFFSET = {
  '10px': '-left-[0.625rem]',
  '20px': '-left-[1.25rem]',
}

export const ReviewCardBackground = ({
  leftOffset,
}: {
  leftOffset?: LeftOffsetType
}): JSX.Element => {
  return (
    <div
      className={`block absolute flex h-full flex-col justify-between pt-10 ${
        LEFT_OFFSET[leftOffset ?? '20px']
      } desktop:hidden pointer-events-none`}
    >
      <ReviewBackgroundElements variant='top' />
      <ReviewBackgroundElements variant='bottom' />
    </div>
  )
}

export const ReviewCardSliderWrapper = ({
  children,
  leftOffset,
}: IReviewCardSliderWrapper): JSX.Element => {
  return (
    <div className='relative min-h-[32.5rem] pb-[5.5rem] desktop:pb-[1rem]'>
      <ReviewCardBackground leftOffset={leftOffset} />
      {children}
    </div>
  )
}

export default ReviewCard
